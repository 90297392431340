* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* body {
  background-color: #262254;
  color: white;
  padding: 20px;
} */

.store_commission_card{
  height: fit-content;
}

.update-logo-button{
  float: right;
}
.update-logo-button:disabled{
  color: white;
  pointer-events: none;
  background-color: #0d6efd;
  border-color: #0d6efd;
  opacity: 0.6;
}
.shopify-container {
  background-color: #262254;
  color: white;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
  display: flex;
  flex-wrap: wrap;
}

/* Testimonial Section (Left) */
.testimonial-section {
  width: 50%;
  padding-right: 40px;
}

.heading {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1.2;
  max-width: 500px;
}

.quote-mark {
  color: rgba(255, 255, 255, 0.3);
  margin: 20px 0;
}

.testimonial-text {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  max-width: 420px;
}

.testimonial{
  margin-left: 20px;
  display: flex;
}

.testimonial-author {
  display: flex;
  align-items: start;
  margin-top: 20px;
}

.testimonial-image {
  margin-right: 15px;
}

.testimonial-image img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
}

.author-name {
  font-size: 18px;
  font-weight: bold;
  margin-left: 20px;
}

/* Form Section (Right) */
.form-section {
  width: 50%;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
}

label .required {
  color: white;
  margin-left: 3px;
}

/*  */
.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"]
, select {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
}

.phone-input {
  display: flex;
  align-items: center;
}

.country-code {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 12px;
  border-radius: 4px 0 0 4px;
  border-right: 1px solid #ccc;
}

.country-code svg {
  margin-right: 5px;
}
.country-mobile-code{
  color: #00000000;
}
.phone-input input[type="tel"] {
  border-radius: 0 4px 4px 0;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #888;
  pointer-events: none;
}

.input-icon {
  position: relative;
}

.input-icon input {
  padding-right: 40px;
}

.input-icon .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.icon svg {
  width: 20px;
  height: 20px;
  fill: #888;
}

.checkbox-group {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 8px;
  margin-top: 3px;
}

.checkbox-group label {
  font-size: 14px;
  line-height: 1.4;
}

/* .btn {
  display: block;
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  margin-bottom: 15px;
} */

.btn-secondary:disabled{
  background-color: #40a798;
  color: white;
  display: block;
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  margin-bottom: 15px;
}

.btn-upload-video{
  background-color: #6c757d;
  color: white;
}

.divider {
  text-align: center;
  margin-bottom: 15px;
  position: relative;
}

.divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: transparent;
}

.divider span {
  display: inline-block;
  position: relative;
  padding: 0 10px;
  background-color: #23265F;
  font-size: 14px;
}

.us-flag-icon {
  width: 20px;
  height: 14px;
  margin-right: 5px;
}

/* Responsive layout */
@media (max-width: 768px) {
  .testimonial-section, 
  .form-section {
    width: 100%;
    padding-right: 0;
  }
  
  .testimonial-section {
    margin-bottom: 40px;
  }
}
.demo-customers,.pb-dev-first-form-title,.pb-dev-threed-form-title, .purebrand-form-main, #GuestInfoForm, .demo-review, .demo-img-text,.pb-dev-quote-text,.pb-dev-footer,.pb-dev-picker-container,.flatpickr-months,.pb-dev-schedule-btn-block,.pb-dev-display-date-time-preview,.pb-dev-form-second,.pb-dev-form-main-text,.pb-dev-second-form-title,.flatpickr-weekday,.flatpickr-day{
    font-family: 'Inter' !important;
  }
  .pb-dev-image-feeed{
    width: 100% !important;
    height: 100% !important;
  }
  .pb-dev-mobile-video,.pb-dev-mobile-video1{
    display: none;
  }
  #emailError,#nameError,#categoryError{
     color: red; display: none; 
  }
  .pb-dev-svg-for-round{
    width:"507px";
    height:"33px";
  }
  .pb-dev-svg-display{
    text-align: center;
  }
  
  img.pb-dev-purebrand-logo {
    height: 100% !important;
    width: 100% !important;
  }
  
  .pb-dev-svg-display-main{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .pb-dev-svg-display-round{
    animation: animName 2s linear infinite;
  }
  @keyframes animName {
    0%{
       transform: rotate(0deg);
      }
   100%{
       transform: rotate(360deg);
      }
   }
   
  
  #pb-dev-GuestInfoForm input.error{
    border: #ef1b1b 2px solid;
  }
  .pb-dev-input-container .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4{
    padding-left: 3px;
  }
  .pb-dev-selected-date-and-month, .pb-dev-selected-date-and-time, .pb-dev-store-name-app {
    font-size: 16px  !important; 
    font-weight: bold  !important;
    color: #23265F !important;
  }
  div.intl-tel-input.allow-dropdown .flag-container, div.intl-tel-input.separate-dial-code .flag-container{
    position: absolute !important;
  }
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2{
    margin-bottom: 0px;
  }
  .pb-dev-demo-review .pb-dev-demo-review-content img{
    width: auto;
    height: auto;
  }
  
  .pb-dev-button-all-date-get::-webkit-scrollbar-track, .pb-dev-time-slots::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #f1f1f1;
  }
  
  .pb-dev-button-all-date-get::-webkit-scrollbar, .pb-dev-time-slots::-webkit-scrollbar
  {
      width: 6px;
      background-color: #f1f1f1;
  }
  
  .pb-dev-button-all-date-get::-webkit-scrollbar-thumb, .pb-dev-time-slots::-webkit-scrollbar-thumb
  {
      background-color: #8a8a8a;
  }
  
  textarea#pb-dev-timeNotes{
    border: 2px solid #e8e8e8;
      border-radius: 8px;
      font-size: 9px;
      resize: none;
      font-weight: bold;
  }
  
  .pb-dev-demo-img-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  
  .pb-dev-demo-left-col {
    width: 50%;
    padding: 0 10px;
  }
  
  p.pb-dev-demo-contentss {
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 29.05px;
    text-align: left;
    color: #23265F;
    letter-spacing: normal;
    max-width: 79%;
  }
  
  .pb-dev-demo-right-col {
    width: 50%;
    padding: 0 10px;
  }
  
  .pb-dev-demo-container {
    max-width: 1263px;
    padding: 0 15px 50px;
    margin: 0 auto;
  }
  
  a.pb-dev-btn_one {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
    background: #23265f;
    color: #ffffff;
    padding: 20px 30px 20px 30px;
    border-radius: 40px;
    display: block;
    text-decoration: none;
    text-align: center;
  }
  
  .pb-dev-demo-btns {
    display: flex;
    flex-wrap: wrap;
  }
  
  a.pb-dev-btn_two {
    display: flex;
    align-items: center;
    padding: 20px 30px 20px 30px;
    border-radius: 40px;
    height: 62px;
    border: 1px solid #000000;
    color: #1e3a44;
    text-decoration: none;
    justify-content: center;
  }
  
  .pb-dev-demo-left-btn {
    margin-right: 15px;
  }
  
  .pb-dev-btn_icons {
    display: flex;
    margin-right: 10px;
  }
  
  .pb-dev-demo-title {
    font-size: 80px;
    font-weight: 700;
    line-height: 90px;
    text-align: left;
    margin-bottom: 15px;
    font-family: var(--font-body-family);
    color: #23265f;
  }
  
  .pb-dev-demo-left-inner {
    max-width: 499px;
  }
  
  .pb-dev-demo-img-text {
    padding: 50px 0;
  }
  
  #pb-dev-GuestInfoForm .hide {
    display: none !important;
  }
  
  .pb-dev-demo-cust-inner-right {
    display: flex;
  }
  
  #pb-dev-GuestInfoForm label.error {
    color: red !important;
    font-size: 15px;
    font-family: "Mulish";
    letter-spacing: 0;
    }
    .pb-dev-Guestemail-label{
      display: block;
    }
    #pb-dev-Guestphone-error-msg{
      color: red !important;
    }
  
  .pb-dev-submit-button.pb-dev-disabled {
    cursor: none;
    pointer-events: none;
    opacity: 0.6;
  }
  
  #pb-dev-GuestInfoForm .iti__flag,
  #pb-dev-GuestInfoForm .iti-flag {
    display: block !important;
  }
  
  #pb-dev-GuestInfoForm .selected-flag {
    background: #fff !important;
  }
  
  #pb-dev-GuestInfoForm input#pb-dev-Guestphone {
    width: 100% !important;
    padding-left: 100px !important;
    left: 0px !important;
  }
  /* .pb-dev-time-slots::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
  }
  
  .pb-dev-time-slots::-webkit-scrollbar
  {
      width: 6px;
      background-color: #F5F5F5;
  }
  
  .pb-dev-time-slots::-webkit-scrollbar-thumb
  {
      background-color: #000000;
  } */
  
  
  #pb-dev-GuestInfoForm .intl-tel-input.allow-dropdown {
    width: 100% !important;
    margin: 0 !important;
  }
  
  .pb-dev-demo-customers {
    background: #f9f8fe;
    padding: 50px 0px;
  }
  
  .pb-dev-demo-customer-container {
    max-width: 1244px;
    margin: 0 auto;
    padding: 0 10px;
  }
  
  .pb-dev-demo-customer-row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .pb-dev-demo-cust-col-left {
    width: 60%;
    padding-right: 85px;
  }
  
  .pb-dev-demo-cust-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 48.41px;
    text-align: left;
    letter-spacing: normal;
    font-family: var(--font-body-family);
  }
  
  .pb-dev-demo-cust-col-right {
    width: 80;
  }
  
  .pb-dev-demo-imgs {
    box-shadow: 0px 4px 9px 0px #0000000d;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 60px;
  }
  
  img.pb-dev-demo-img-icon {
    width: 30px;
    height: 30px;
  }
  
  .pb-dev-demo-cust-box-inners {
    display: flex;
  }
  
  .pb-dev-demo-content {
    padding-left: 15px;
  }
  
  p.pb-dev-demo-cust-content {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #a6a6a6;
  }
  
  .pb-dev-demo-cust-review {
    display: flex;
  }
  
  .cust-review-wrap img {
    width: auto;
  }
  
  span.pb-dev-review-author {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
  }
  
  span.pb-dev-rating-star {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
    padding-bottom: 10px;
    display: block;
  }
  
  .cust-box:first-child .cust-review-wrap {
    padding-right: 40px;
  }
  
  .pb-dev-demo-cust-box {
    padding-bottom: 40px;
  }
  
  h2.pb-dev-demo-titles {
    font-size: 28px;
    font-weight: 700;
    line-height: 33.89px;
    text-align: left;
    margin-bottom: 12px;
    margin-top: 0;
    font-family: Inter;
    letter-spacing: normal;
  }
  
  .pb-dev-second-form-title {
    color: #ffffff;
  }
  
  @media (max-width: 767px) {
    .pb-dev-svg-for-round{
      width:"320px";
      height:"17px";
    }
    .pb-dev-demo-cust-col-left {
      width: 100%;
      padding-right: 0;
      padding-bottom: 30px;
    }
  
    .pb-dev-demo-cust-col-right {
      width: 100%;
    }
  
    .pb-dev-demo-cust-title {
      font-size: 30px;
      line-height: 38.41px;
    }
  
    h2.pb-dev-demo-titles {
      font-size: 24px;
      line-height: 27.89px;
    }
  
    .cust-box:first-child .cust-review-wrap {
      padding-right: 20px;
    }
  
    .pb-dev-demo-img-text {
      overflow: hidden;
    }
  
    .pb-dev-demo-right-inner {
      width: 100%;
      height: 100%;
    }
  
    .pb-dev-demo-right-inner img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    .pb-dev-form-first {
      flex-wrap: wrap;
      padding: 0px 20px !important;
    }
  }
  
  
  .pb-dev-form-main {
    justify-content: space-between;
    background: #23265F;
    min-height: 400px;
    padding: 30px 100px;
  }
  
  
  .pb-dev-form-first {
    display: flex;
  }
  
  .pb-dev-review-section {
    flex: 1;
    padding: 0px;
  }
  
  .pb-dev-review-section h1 {
    margin-bottom: 20px;
    width: 100%;
    height: 120px;
    top: 1685px;
    left: 172px;
    gap: 0px;
    opacity: 0px;
    color: #FFFFFF;
    font-size: 41px;
    text-align: left;
  }
  
  .pb-dev-blockquote {
    margin: 0;
    padding: 0;
  }
  
  .pb-dev-blockquote .pb-dev-footer {
    font-weight: bold;
    color: #fff;
    font-size: 20px;
  }
  
  .pb-dev-review-section img {
    margin: 20px;
    width: 85px;
    height: 85px;
  }
  
  .pb-dev-form-section {
    flex: 1;
    padding: 20px;
    /* background-color: #3944bc; */
    border-radius: 8px;
  }
  
  .pb-dev-form-section h2 {
    margin-bottom: 20px;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 36.31px;
    text-align: left;
  
  
  }
  
  #pb-dev-GuestInfoForm {
    display: flex;
    flex-direction: column;
  }
  
  #pb-dev-GuestInfoForm label {
    margin-top: 10px;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
  
  
  }
  
  .pb-dev-form-data {
    padding: 10px;
    margin-top: 5px;
    border: none;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    /* top: 163px; */
    left: 93px;
    gap: 0px;
    border-radius: 10px 10px 10px 10px;
  
  }
  
  .pb-dev-checkbox-main {
    display: flex;
    align-items: center;
    margin-top: 10px;
  
  }
  
  .pb-dev-checkbox-main .pb-dev-checkbox {
    margin-right: 10px;
  }
  .pb-dev-first-page-block-in-button-main{
    display: grid;
    gap: 5px;
    margin-top: 15px;
  }
  .pb-dev-or-design{
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
  }
  .pb-dev-submit-button {
    padding: 15px;
    background: #54BD95;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    /* text-align: left; */
    /* width: 440px; */
    height: 60px;
    gap: 0px;
    border-radius: 10px;
    width: 100%;
  
  }
  
  .pb-dev-text {
    width: 333px;
    /* height: 83px; */
    top: 1926px;
    left: 172px;
    gap: 0px;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 29px;
  }
  
  
  
  /*  */
  
  .pb-dev-threed-form-title,
  .pb-dev-second-form-title,
  .pb-dev-first-form-title {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
  }
  
  .pb-dev-text-last-page {
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
  
  }
  
  .pb-dev-meeting-info {
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  
  }
  
  .pb-dev-picker-container {
    /* margin: 20px; */
    text-align: left;
    width: 100%;
  }
  
  .pb-dev-form-second .flatpickr-calendar {
    /* width: 100%; */
    width: 307.875px;
  }
  
  .pb-dev-time-slot-block-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-height: 200px;
    width: 430px; */
    width: 100%;
    padding-left: 20px;
  
  }
  #pb-dev-GuestInfoForm .intl-tel-input .flag-container{
    padding: 10px 0 !important;
  }
  .pb-dev-time-slots {
    width: 100%;
    overflow-y: auto;
    padding-right: 15px;
  }
  
  .pb-dev-time-slots div {
    padding: 5px 0;
    text-align: center;
    margin: 0px 0 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    height: 35px;
    gap: 0px;
    border-radius: 10px;
    opacity: 0px;
    border: 1px solid #23265F80
  }
  
  .pb-dev-time-slots div.selected {
    color: white;
    border: 1px solid #23265F80;
    background: #23265F;
    width: 100%;
    height: 35px;
    gap: 0px;
    border-radius: 10px;
    border: 1px;
    opacity: 0px;
  }
  
  .pb-dev-schedule-btn {
    width: 100%;
    padding: 20px;
    background-color: #2C2C9E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .pb-dev-date-time-box {
    background-color: #ffffff;
    display: flex;
    max-width: 1008px;
    height: 520px;
    gap: 0px;
    border-radius: 12.84px;
    border: 0.82px;
    opacity: 0px;
    padding: 25px 0 25px 25px;
    margin: 0 auto;
    width: 100%;
  }
  
  
  /*  */
  .pb-dev-form-second .flatpickr-day.flatpickr-disabled,
  .pb-dev-form-second .flatpickr-day.flatpickr-disabled:hover {
    color: #747474 !important;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.76px;
    text-align: center;
    width: 31px;
    height: 31px;
    gap: 0px;
    opacity: 0px;
  }
  
  /* .flatpickr-day{
          width: 31px;
          height: 31px;
          gap: 0px;
          opacity: 0px;
          background: #23265F26;
  
        } */
  .pb-dev-meeting-info p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    text-align: left;
    color: #23265F;
    padding-left: 14px;
  }
  
  .pb-dev-meeting-info div {
    display: flex;
    align-items: center;
  }
  
  #pb-dev-datepickernew {
    display: none;
  }
  .pb-dev-form-second .flatpickr-calendar.inline{
    margin: 0px;
  }
  
  .pb-dev-form-second .flatpickr-calendar.inline+label {
    display: block;
    text-align: left;
    width: 100%;
    color: #23265F;
    font-size: 13.8px;
    font-weight: 700;
    padding: 10px 0;
  }
  
  select#pb-dev-timezone {
    color: #23265F;
    padding: 10px 10px 10px 0;
    border: 0;
    outline: none;
    width: 307.875px;
    box-shadow: none;
  }
  
  .pb-dev-form-second .flatpickr-day {
    background: #23265F26 !important;
    color: #23265F !important;
  }
  
  .pb-dev-form-second .flatpickr-day.selected {
    background: #23265F !important;
    color: #FFFFFF !important;
  }
  
  .pb-dev-form-second .flatpickr-day.flatpickr-disabled {
    color: #747474 !important;
    background: transparent !important;
  }
  
  .pb-dev-form-second,
  .pb-dev-form-threed {
    display: none;
  }
  /* 
  .video-with-text {
    padding: 50px 0;
  }
  
  .video-container {
    width: 100%;
    border-radius: 4px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  }
  
  .video-container .video-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-container video {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  
  .play-button-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    pointer-events: none;
  }
  
  .play-button-wrapper #circle-play-b {
    cursor: pointer;
    pointer-events: auto;
  }
  
  .play-button-wrapper #circle-play-b svg {
    width: 80px;
    height: 80px;
    fill: #fff;
    stroke: #fff;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    opacity: 0.9;
  }
  
  .video-text-container {
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 10px;
  }
  
  .video-with-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  a.video-with-link {
    display: inline-block;
    background: #23265F;
    color: #ffffff;
    text-decoration: none;
    padding: 20px 30px;
    border-radius: 40px;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
  }
  
  .video-with-left-col {
    max-width: 380px;
  }
  
  h3.video-with-title {
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #23265F;
  }
  
  p.video-with-content {
    font-size: 24px;
    font-weight: 400;
    line-height: 29.05px;
    text-align: left;
  }
  
  .video-with-left {
    width: 40%;
  }
  
  .video-with-right {
    width: 60%;
  } */
  
  button.pb-dev-request-time-btn {
    background: #54BD95;
    width: auto;
    text-align: center;
    margin: 40px auto;
    display: block;
  }
  #pb-dev-button-add-more-date{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    color: #23265F;
    background: transparent;
    border: transparent;
    text-align: center;
    width: 100%;
    margin-bottom: 7px;
  }
  .pb-dev-button-all-date-get{
    display: flex;
    flex-wrap: wrap;
  }
  
  .pb-dev-demo-right-inner img {
    width: 100%;
  }
  
  .pb-dev-form-main-text {
    margin: auto;
    width: 60%;
  }
  
  .pb-dev-threed-form-title {
    font-size: 5rem;
  }
  
  .pb-dev-container-image {
    position: relative;
    background-image: url('https://cdn.shopify.com/s/files/1/0678/0813/3441/files/Group_10462_1.png?v=1720679196');
    width: 513.1px;
    height: 512.15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
  }
  
  .pb-dev-video-main-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .pb-dev-video-inner-box {
    position: absolute;
    top: 11.5%;
    left: 17%;
    width: 34.75%;
    height: 76%;
    object-fit: cover;
    pointer-events: none;
    transform: rotate(6.7deg);
    border-radius: 14px;
    overflow: hidden;
  }
  
  .pb-dev-demo-right-inner img {
    width: 494px;
  }
  
  /* .pb-dev-mobile-video {
        position: absolute;
        top: 55px;
        left: 81px;
        width: 175PX;
        height: 378PX;
        object-fit: cover;
        pointer-events: none;
        transform: rotate(7deg);
        border-radius: 14px;
    } */
  .pb-dev-mobile-video {
    /* position: absolute;
      top: 11%;
      left: 14%; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    border-radius: 14px;
  }
  
  .pb-dev-demo-info {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    letter-spacing: normal;
    color: #A6A6A6;
  }
  
  @media(max-width: 991px) {
    .pb-dev-demo-img-text {
      padding: 50px 0;
    }
  
    .pb-dev-demo-title {
      font-size: 48px;
      line-height: 52px;
    }
  
    p.pb-dev-demo-content {
      font-size: 20px;
      line-height: 24.2px;
    }
  
    .pb-dev-demo-right-inner img {
      width: 360px;
    }
  
    .pb-dev-mobile-video {
      top: 42px;
      left: 57px;
      width: 130PX;
      height: 280PX;
    }
  
    .pb-dev-demo-cust-inner-right {
      display: block;
    }
  }
  
  @media(max-width: 426px) {
    /* .video-with-left {
      width: 100%;
    }
  
    .video-with-right {
      width: 100%;
    }
  
    .video-with-left-col {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    h3.video-with-title {
      font-size: 34px;
      line-height: 34px;
    }
  
    p.video-with-content {
      font-size: 18px;
    } */
  
    .pb-dev-demo-left-inner {
      max-width: 100%;
      margin-bottom: 40px;
    }
  
    .pb-dev-review-section {
      width: 100%;
    }
  
    .pb-dev-form-main-text {
      margin: auto;
      width: 100%;
    }
  
  }
  
  .pb-dev-image-with-text-block {
    display: flex;
  
  }
  
  button.pb-dev-request-time-btn {
    width: 100%;
    margin: 24px 12px 7px 9px;
  }
  
  .pb-dev-schedule-btn .pb-dev-soonest-available-identifier {
  
    background: #DEDFE7;
    color: #23265F;
  }
  
  .pb-dev-schedule-btn-block {
    display: flex;
    max-width: 1008px;
    margin: 0 auto;
  }
  
  
  .pb-dev-soonest-available-identifier {
    color: #23265F;
    background: #DEDFE7 !important;
  }
  
  #pb-dev-GuestInfoForm .intl-tel-input.allow-dropdown .flag-container,
  #pb-dev-GuestInfoForm .intl-tel-input.separate-dial-code .flag-container {
    left: 6px !important;
  }
  
  .pb-dev-input-container {
    position: relative;
    width: 100%;
  }
  
  .pb-dev-email-input {
    width: 100%;
    padding: 10px;
    padding-right: 40px;
    /* space for the icon */
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .pb-dev-email-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none;
    /* icon should not block the input */
    color: #aaa;
  }
  
  .pb-dev-display-date-time-preview {
    color: #23265F;
    font-weight: bold;
    font-size: 1rem;
    border: 1px solid #E6E6E6;
    text-align: center;
    margin: 5px 0;
    padding: 2px;
    border-radius: 6px;
    width: 290px;
    padding: 10px;
    text-align: center;
  }
  .pb-dev-display-date-time-preview.active{
    color: #FFFFFF;
    background: #23265F;
  }
  .pb-dev-button-all-date-get{
    width: 307.875px;
    overflow-y: auto;
    height: 85px;
  }
  .pb-dev-powered-by-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    flex-wrap: wrap;
    background: #F9F8FE;
  }
  
  .pb-dev-powered-by-text {
    margin-right: 10px;
    color: #23265F;
  }
  
  .pb-dev-powered-by-logo {
    max-width: 150px;
    height: auto;
  }
  
  /* video.pb-dev-mobile-video1 {
        position: absolute;
        top: 319px;
        left: 178px;
        width: 52PX;
        height: 103PX;
        object-fit: cover;
        pointer-events: none;
        transform: rotate(7deg);
    } */
  video.pb-dev-mobile-video1 {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 10px;
    right: 10px;
    width: 52PX;
    height: 103PX;
    object-fit: cover;
    pointer-events: none;
  }
  
  /* Media query for mobile devices */
  
  @media (max-width: 1200px) {
    .pb-dev-container-image {
      width: 308.31px;
      height: 307.74px;
      text-align: center;
      margin: 0 auto;
    }
  
    .pb-dev-video-inner-box {
      top: 11.5%;
      left: 16.5%;
      width: 35.1%;
      height: 76%;
    }
  
    .pb-dev-demo-img-row {
      align-items: center;
    }
  }
  
  @media (max-width: 800px) {
    .pb-dev-svg-for-round{
      width:"328px";
      height:"25px";
    }
    .pb-dev-powered-by-section {
      flex-direction: column;
      align-items: center;
    }
  
    .pb-dev-powered-by-text {
      margin-right: 0;
      margin-bottom: 5px;
    }
  
    .pb-dev-quote-icon {
      margin-left: 3%;
      width: fit-content;
      background-color: #000000;
    }
  
    .pb-dev-avtar-image {
      display: none;
    }
  
    .pb-dev-quote-text {
      text-align: center;
    }
  
    .pb-dev-review-section h1 {
      text-align: center;
    }
  
    .pb-dev-form-cta {
      display: none;
    }
  
    .pb-dev-blockquote .pb-dev-footer {
      font-weight: bold;
      color: #fff;
      font-size: 1.6rem;
    }
  
    .pb-dev-image-with-text-block {
      justify-content: space-around;
    }
  
    /* video.pb-dev-mobile-video1 {
      position: absolute;
      top: 229px;
      left: 128px;
      width: 40PX;
      height: 81PX;
      object-fit: cover;
      pointer-events: none;
      transform: rotate(7deg);
  } */
  }
  
  
  .pb-dev-demo-review-container {
    max-width: 1244px;
    margin: 0 auto;
    padding: 0 10px;
  }
  
  /* .pb-dev-demo-mobile-review {
        display: none;
    } */
  .pb-dev-demo-mobil-title {
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.03em;
    color: #23265F;
  }
  
  .pb-dev-demo-mobil-infos {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #64607D;
  }
  
  h3.pb-dev-demo-review-title {
    font-size: 16px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
    margin: 0;
    color: #1E3A44;
  }
  
  p.pb-dev-demo-review-info {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 0;
    color: #64607D;
    font-family: Inter;
  }
  
  .pb-dev-demo-review-box {
    display: flex;
    position: relative;
  }
  
  .pb-dev-review-num {
    font-size: 160px;
    font-weight: 900;
    line-height: 204px;
    letter-spacing: -0.02em;
    text-align: left;
    color: rgb(0 0 0);
    opacity: 5%;
    font-family: "Inter", sans-serif;
  }
  
  .pb-dev-demo-review-col {
    max-width: 280px;
  }
  
  .pb-dev-review-number {
    position: absolute;
    right: 0;
  }
  
  .pb-dev-demo-review-content {
    padding-right: 30px;
  }
  
  .pb-dev-demo-review-col {
    margin-bottom: 10px;
  }
  
  .pb-dev-demo-desk-img {
    display: none;
  }
  
  .pb-dev-demo-desk-img img {
    width: 100%;
  }
  
  .pb-dev-demo-review-row {
    margin-top: -75px;
    position: relative;
    background-image: url('https://cdn.shopify.com/s/files/1/0678/0813/3441/files/Vector_335.svg?v=1718018111');
    background-repeat: no-repeat;
    height: 609px;
  }
  
  .pb-dev-demo-review-col:first-child {
    position: absolute;
    right: -100px;
    top: -4%;
  }
  
  .pb-dev-demo-review-col:nth-child(2) {
    position: absolute;
    top: 35%;
    left: 54%;
  }
  
  .pb-dev-demo-review-col:nth-child(3) {
    position: absolute;
    top: 59%;
    left: 16%;
  }
  
  @media(max-width: 1200px) {
    .pb-dev-demo-review-col:nth-child(3) {
      top: 49%;
      left: 3%;
    }
  
    .pb-dev-demo-review-col:nth-child(2) {
      top: 39%;
      left: 39%;
    }
  
    .pb-dev-demo-review-col:first-child {
      right: 0;
      top: 28%;
    }
  
    video.pb-dev-mobile-video1 {
      width: 40px;
      height: 81px;
    }
    .pb-dev-meeting-info {
      display: none;
    }
  }
  
  @media(max-width: 991px) {
    .pb-dev-demo-right-btn {
      display: none;
    }
  
    .pb-dev-demo-btns {
      justify-content: center;
    }
  
    .pb-dev-demo-left-btn {
      margin-right: 0;
    }
  
    .pb-dev-demo-left-col {
      padding: 0 32px;
    }
  
    .pb-dev-demo-right-col {
      padding: 0 10px;
    }
  
    .pb-dev-demo-img-row {
      margin: 0;
    }
  
    .pb-dev-demo-right-inner img {
      width: 100%;
    }
  
    .pb-dev-review-section {
      width: 100%;
    }
  
    .pb-dev-form-main.pb-dev-form-first {
      flex-wrap: wrap;
    }
  
    .pb-dev-form-section {
      width: 100%;
    }
  
    .pb-dev-review-section {
      flex: none;
    }
  
    .pb-dev-quote-icon {
      display: none;
    }
  
    img.pb-dev-avtar-image {
      display: none;
    }
  
    p.pb-dev-text.pb-dev-quote-text {
      width: 100%;
      height: auto;
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      margin: 0 0 10px;
    }
  
    h1.pb-dev-first-form-title {
      height: auto;
      margin: 0px 0 20px;
      font-family: Inter;
      font-size: 40px;
      font-weight: 800;
      line-height: 60px;
      letter-spacing: -0.03em;
      text-align: center;
    }
  
    .pb-dev-blockquote .pb-dev-footer {
      display: none;
    }
  
    .pb-dev-demo-cust-col-right {
      width: 100%;
    }
  
    .pb-dev-demo-cust-box {
      max-width: 489px;
      margin: 0 auto;
    }
  
    .pb-dev-powered-by-section {
      display: none;
    }
  
    p.pb-dev-demo-contentss {
      max-width:100%;
      font-size: 20px;
      line-height: 24.2px;
      max-width: 297px;
    }
  
    .pb-dev-date-time-box {
      width: 100%;
      padding: 15px 0 15px 15px;
    }
  
    .pb-dev-demo-mobile-review {
      display: block;
    }
  
  
    .pb-dev-demo-review-container {
      padding: 0 20px;
    }
  
    p.pb-dev-demo-review-info {
      font-size: 14px;
    }
  
    .pb-dev-demo-mobil-title {
      font-size: 40px;
      font-weight: 800;
      line-height: 48px;
      letter-spacing: -0.03em;
      text-align: left;
      color: #23265F;
      margin-bottom: 12px;
    }
  
    .pb-dev-demo-mobil-infos {
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #64607D;
    }
  
    .pb-dev-demo-review-col:nth-child(3) {
      left: 0%;
      max-width: 219px;
      top: 59%;
    }
  
    .pb-dev-review-number {
      top: -7%;
    }
  
    span.pb-dev-review-num {
      font-size: 204px;
      line-height: 204px;
    }
  
    .pb-dev-demo-review-row {
      background-image: url(https://cdn.shopify.com/s/files/1/0678/0813/3441/files/Vector_335.png?v=1720153524);
      height: 572px;
      background-position: left;
    }
  
    .pb-dev-demo-review-col:nth-child(2) {
      left: 33%;
      max-width: 268px;
    }
  
    .pb-dev-demo-review-col:first-child {
      top: 23%;
      max-width: 227px;
      right: -66px;
    }
  
    /* .pb-dev-demo-review-row {
        background: transparent;
          height: auto;
    } */
    /* .pb-dev-demo-review-content img {
        display: none;
    } */
    /* .pb-dev-demo-review-col {
        max-width: 100%;
          position: unset !important;
    } */
    /* .pb-dev-review-number {
        position: unset;
    } */
    /* .pb-dev-demo-review-row {
        flex-direction: column-reverse;
        display: flex;
         margin-top: 0px;
    } */
    .pb-dev-demo-mobil-title {
      text-align: center;
    }
  
    .pb-dev-demo-mobil-infos {
      text-align: center;
      margin-bottom: 25px;
    }
  
    /* .pb-dev-demo-review-box {
        max-width: 500px;
        margin: 0 auto;
        align-items: center;
    } */
    .pb-dev-form-main {
      padding: 30px 30px;
    }
  
    .pb-dev-meeting-info {
      display: none;
    }
  }
  
  @media(min-width: 991px) {
    .pb-dev-demo-review-col:nth-child(2) h3.pb-dev-demo-review-title {
      max-width: 144px;
    }
  
    .pb-dev-demo-review-col:nth-child(1) h3.pb-dev-demo-review-title {
      max-width: 122px;
    }
  }
  
  @media screen and (min-device-width: 801px) and (max-device-width: 991px) {
    .pb-dev-demo-review-row {
      background-size: contain;
    }
  }
  
  @media(max-width: 767px) {
  
    /* .pb-dev-mobile-video{
            width: 137px;
            height: 291px;
            top: 45px;
            left: 58px;
        } */
    .pb-dev-demo-left-inner {
      margin: 0 auto 40px;
    }
  
    .pb-dev-demo-review-content {
      padding-right: 41px;
    }
  
    .pb-dev-time-slots {
      max-width: 100%;
    }
  
    .pb-dev-review-number {
      position: unset;
    }
  
    .pb-dev-demo-review-row {
      background: transparent;
      height: auto;
    }
  
    .pb-dev-demo-review-row {
      flex-direction: column-reverse;
      display: flex;
      margin-top: 0px;
    }
  
    .pb-dev-date-time-box {
      flex-wrap: wrap;
      height: auto;
        padding: 15px 15px 15px 15px;
  
    }
  
    .pb-dev-demo-review-content img {
      display: none;
    }
  
    .pb-dev-demo-review-box {
      max-width: 500px;
      margin: 0 auto;
      align-items: center;
    }
  
    select#pb-dev-timezone {
      width: 100%;
    }
  
    .pb-dev-form-second .flatpickr-calendar.inline+label {
      margin-top: 10px;
    }
  
    .pb-dev-form-main {
      padding: 10px !important;
    }
  
    .pb-dev-form-second .flatpickr-calendar.animate.inline {
      width: 95% !important;
      margin: auto;
    }
  
    .pb-dev-form-second .flatpickr-months {
      width: 100% !important;
    }
  
    .pb-dev-form-second .flatpickr-rContainer {
      width: 100% !important;
    }
  
    .pb-dev-form-second .flatpickr-days {
      width: 100% !important;
    }
  
    .dayContainer {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
    }
  
    .pb-dev-demo-right-btn {
      width: 100%;
    }
  
    .pb-dev-demo-left-btn {
      /* width: 100%; */
      margin: 0 0 15px;
    }
  
    .pb-dev-demo-title {
      font-size: 48px;
      line-height: 52px;
    }
  
    h1.pb-dev-first-form-title {
      font-size: 40px;
      line-height: 50px;
      max-width: 359px;
      margin: 0px auto 20px;
  
    }
  
    p.pb-dev-text.pb-dev-quote-text {
      display: none;
    }
  
    .pb-dev-demo-cust-box {
      max-width: 312px;
    }
  
    .pb-dev-demo-mobil-title {
      text-align: left;
    }
  
    .pb-dev-demo-mobil-infos {
      text-align: left;
    }
  
    p.pb-dev-text.pb-dev-quote-text {
      display: none;
    }
  
    .pb-dev-demo-review-col {
      max-width: 100% !important;
      position: unset !important;
    }
  
    .pb-dev-demo-mobile-review {
      max-width: 500px;
      margin: 0 auto;
    }
  
    span.pb-dev-review-num {
      font-size: 160px;
      line-height: 204px;
    }
  
    .pb-dev-demo-right-col {
      width: 100%;
    }
  
    .pb-dev-demo-left-col {
      width: 100%;
    }
  }
  .pb-dev-form-second .flatpickr-day.flatpickr-disabled, .pb-dev-form-second .flatpickr-day.flatpickr-disabled:hover {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flatpickr-innerContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  
  .flatpickr-weekdaycontainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr) !important; /* 7 equal columns */
    gap: 5px !important;
    text-align: center !important;
    padding-bottom: 10px !important;
  }
  
  .flatpickr-weekday {
    font-weight: bold !important;
  }
  
  .dayContainer {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important; /* 7 equal columns for days */
  }
  
  .flatpickr-day {
    cursor: pointer !important;
    width: 100% !important;
    margin: 0 auto !important;
  }
  
  .flatpickr-day.flatpickr-disabled {
    color: #ccc !important; /* Style for disabled dates */
    cursor: not-allowed !important;
  }
  @media(max-width: 450px){
    .flatpickr-day {
      max-width: 26px !important;
      height: 26px !important;
      line-height: 26px !important;
  }
  }
  
  .pb-select-svg-icon{
    display: flex;
    align-items: center;
    gap: 2px;
  }
  .pb-dev-display-date-time-preview.active .pb-dev-selected-date ,.pb-dev-display-date-time-preview.active .pb-dev-time-date{  
    color: #fff;
  }
  
  /* Remove only the default arrow */
  .pb-dev-category-select {
    padding-right: 30px; /* Add space for custom arrow */
    -webkit-appearance: none; /* Chrome/Safari */
    -moz-appearance: none; /* Firefox */
    border-radius: 4px;
    color: black;
  }
  
  /* Keep focus and other styles intact */
  .pb-dev-category-select:focus {
    outline: none; /* Optional: Adjust outline as needed */
  }
  
  /* Container styling remains untouched */
  .pb-dev-input-container {
    position: relative;
  }
  
  /* Align the custom SVG */
  .pb-dev-category-icon {
    position: absolute;
    right: 9px;
    top: 55%;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent interaction with SVG */
  }
  .pb-dev-category-select:invalid {
    color: gray;
  }
  
  /* When the select value is valid (not empty), reset the color */
  .pb-dev-category-select:valid {
    color: black; 
  }
  /* Style for enabled options */
  .pb-dev-category-select option:not(:disabled) {
    color: black; 
  }
  
  /* Set the color for the disabled placeholder option */
  .pb-dev-category-select option:disabled {
    color: rgb(170, 170, 170);
  }
  .small-video-preview {
    position: absolute;
    bottom: 20%;
    left: 33%;
    width: 80px;
    height: 60px;
    transform: rotate(6.7deg) scale(1); /* default scale */
  }
  
  @media only screen and (max-width: 767px) {
    .small-video-preview {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) rotate(6.7deg) scale(1); /* adjust scale if needed */
    }
  }
  